$(document).ready(function() {
    if ($('.block.login').length > 0){
        $('#input_container_username input').attr("placeholder", "Vul uw gebruikersnaam in");
        $('#input_container_password input').attr("placeholder", "Vul uw wachtwoord in");
        $('#login_retrievepassword_container').insertBefore("#btn_login");
    }

    if($('li.logo img').length > 0){
        $("li.logo img").after("<span class='title'>KCOZ Klachtencommissie</span>");
    }
});